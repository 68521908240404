<template>
    <b-container fluid>
      <b-row>
        <LayoutHeader />
        <b-col sm="12">
          <b-row>
            <b-col lg="3" class="profile-left">
              <FeatureCard />
            </b-col>
            <b-col lg="6" class="profile-center">
              <iq-card>
                <template v-slot:headerTitle>
                  <h4 class="card-title">{{ $t('select-services') }}</h4>
                </template>
                <template v-slot:headerAction>
<!--                  <button class="bg-transparent border-0" @click="selectAllFeature()">-->
<!--                    <span class="cursor-pointer">-->
<!--                      Select All-->
<!--                    </span>-->
<!--                  </button>-->
                </template>
                <template v-slot:body>
                    <b-row>
                        <b-col cols = "4" v-for="service in services.data" :key="service.id">
                          <div class="service px-3 d-flex align-items-center mb-2" @click="addServices(service.id)" :class="serviceIds.includes(service.id)? 'active' : ''">
                            <span>
                              <i :class="service.icon"></i>
                            </span>
                            <span class="mx-2">
                                {{ service[`${$i18n.locale}_name`] }}
                            </span>
                          </div>
                    </b-col>
                    <b-col sm="12">
                          <div class="pagination d-flex justify-content-center pt-4">
                              <b-pagination
                                  @change="getServices(page)"
                                  v-model="page"
                                  :total-rows="services.total"
                                  :per-page="services.per_page"
                              ></b-pagination>
                            </div>
                        </b-col>
                    <b-col sm="12">
                        <div class="controll py-2 d-flex justify-content-center">
                            <b-button variant="primary" @click="submit">{{$t('save')}}</b-button>
                        </div>
                    </b-col>
                    </b-row>
                </template>
              </iq-card>
            </b-col>
            <b-col lg="3" class="profile-right">
              <MapCard />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </template>
<script>
import LayoutHeader from '@/components/hotelsComponents/LayoutHeader.vue'
import FeatureCard from '@/components/hotelsComponents/FeatureCard.vue'
import MapCard from '@/components/hotelsComponents/MapCard.vue'
export default {
  components: {
    LayoutHeader,
    FeatureCard,
    MapCard
  },
  data () {
    return {
      page: 1,
      serviceIds: []
    }
  },
  methods: {
    selectAllFeature () {
      this.services.data.map((el) => {
        this.serviceIds.push(el.id)
      })
    },
    submit () {
      this.addHotelServices({
        serviceIds: this.serviceIds
      })
    },
    addServices (id) {
      if (this.serviceIds.includes(id)) {
        this.serviceIds = this.serviceIds.filter(el => el !== id)
      } else {
        this.serviceIds.push(id)
      }
    }
  },

  mounted () {
    this.getServices()
    this.getHotel().then(() => {
      this.hotel.services.map((el) => {
        this.serviceIds.push(el.id)
      })
    })
  }
}
</script>
<style>

.service {
        border:1px solid var(--primary);
        color:var(--primary);
        border-radius: 15px;
        margin-bottom: 8px;
        display: flex;
        align-items: center;
        gap:5px;
        cursor: pointer;
}
.service.active {
  background-color:var(--primary);
        color:#fff;
}

</style>
